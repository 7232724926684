import {
  NewsDetailPage,
  Link,
  Asset,
  NewsDetailPageContentFragmentsItem,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKNewsDetailPage } from '@/models/DKNewsDetailPage';
import { DKAsset } from '@/models/DKAsset';
import { DKLink } from '@/models/DKLink';
import { DKRichText } from '@/models/DKRichText';
import { DKNewsDetailPageContentFragmentsItem } from '@/models/DKNewsDetailPageContentFragmentsItem';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { ContactFragmentFactory } from '@/models/factories/ContactFragmentFactory';
import { QuoteFactory } from '@/models/factories/QuoteFactory';
import { RichTextFactory } from '@/models/factories/RichTextFactory';
import { GenericShelfFragmentFactory } from '@/models/factories/GenericShelfFragmentFactory';

export class NewsDetailPageFactory extends GenericFactory<
  NewsDetailPage,
  DKNewsDetailPage
> {
  contentType = 'NewsDetailPage';
  requiredProperties = [];

  protected map(source: NewsDetailPage): DKNewsDetailPage {
    let headerImage: DKAsset | undefined;
    let headerImageMobile: DKAsset | undefined;
    const links: DKLink[] = [];
    const downloads: DKAsset[] = [];
    const contentFragments: DKNewsDetailPageContentFragmentsItem[] = [];
    let body: DKRichText | undefined;
    let metaImage: DKAsset | undefined;

    const assetFactory = new AssetFactory(this.locale);
    const linkFactory = new LinkFactory(this.locale);
    const richTextFactory = new RichTextFactory(this.locale);

    if (source.headerAsset) {
      try {
        const { asset, assetMobile } = source.headerAsset;

        if (asset) {
          headerImage = assetFactory.create(asset);
        }

        if (assetMobile) {
          headerImageMobile = assetFactory.create(assetMobile);
        }
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HeaderImage');
          console.warn(e);
          console.info(e.data);
        } else {
          throw e;
        }
      }
    }

    if (source.linksCollection?.items) {
      const items: Link[] = source.linksCollection.items as Link[];
      items.forEach((link: Link) => {
        try {
          links.push(linkFactory.create(link));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate LinkResource');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.downloadsCollection?.items) {
      const items: Asset[] = source.downloadsCollection.items as Asset[];
      items.forEach((asset: Asset) => {
        try {
          downloads.push(assetFactory.create(asset));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate AssetResource');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.body) {
      try {
        body = richTextFactory.create(source.body);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HeaderImage');
          console.warn(e);
          console.info(e.data);
        } else {
          throw e;
        }
      }
    }

    //Content Fragments
    if (source.contentFragmentsCollection?.items) {
      const items: NewsDetailPageContentFragmentsItem[] = source
        .contentFragmentsCollection
        .items as NewsDetailPageContentFragmentsItem[];

      const contactFragmentFactory = new ContactFragmentFactory(this.locale);
      const quoteFragmentFactory = new QuoteFactory(this.locale);
      const genericShelfFactory = new GenericShelfFragmentFactory(this.locale);

      items.forEach((fragment: NewsDetailPageContentFragmentsItem) => {
        switch (fragment.__typename) {
          case 'ContactFragment':
            try {
              contentFragments.push(contactFragmentFactory.create(fragment));
            } catch (e) {
              if (e instanceof PropertyRequiredError) {
                console.warn('Could not generate ContactFragment');
                console.warn(e);
              } else {
                throw e;
              }
            }
            break;
          case 'Quote':
            try {
              contentFragments.push(quoteFragmentFactory.create(fragment));
            } catch (e) {
              if (e instanceof PropertyRequiredError) {
                console.warn('Could not generate Quote');
                console.warn(e);
              } else {
                throw e;
              }
            }
            break;
          case 'GenericShelfFragment':
            try {
              contentFragments.push(genericShelfFactory.create(fragment));
            } catch (e) {
              if (e instanceof PropertyRequiredError) {
                console.warn('Could not generate GenericShelfFragment');
                console.warn(e);
              } else {
                throw e;
              }
            }
            break;

          default:
            console.warn(`No mapping for ${fragment.__typename} provided!`);
        }
      });
    }

    if (source.metaImage) {
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKNewsDetailPage, {
      ...source,
      headerImage,
      headerImageMobile,
      body,
      contentFragments,
      links,
      downloads,
      metaImage,
    });
  }
}
