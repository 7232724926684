import gql from 'graphql-tag';

export const localGenericShelfFragmentQuery = (
  UseCaseResourceLink: string,
  CareerInsightPageResourceLink: string
) => gql`
  query (
    $id: String!
    $locale: String = "en-US"
    $preview: Boolean = false
    $skip: Int
    $limit: Int
  ) {
    genericShelfFragment(id: $id, locale: $locale, preview: $preview) {
      sys {
        id
      }
      shortTitle
      preTitle
      title
      description
      type
      background
      genericShelfElementsCollection(skip: $skip, limit: $limit) {
        items {
          ... on GenericShelfElement {
            __typename
            sys {
              id
            }
            preTitle
            title
            description
            genericElementLink: link
            image {
              title
              fileName
              size
              url
              contentType
            }
            video {
              ... on Video {
                sys {
                  id
                }
                title
                caption
                videoAsset: video {
                  title
                  fileName
                  url
                  size
                  description
                }
                thumbnail {
                  title
                  fileName
                  url
                  size
                  description
                }
                fieldOfExpertise
                region
              }
              ... on ExternalVideo {
                sys {
                  id
                }
                title
                caption
                videoUrl: video
                thumbnail {
                  title
                  fileName
                  url
                  size
                  description
                  contentType
                }
              }
            }
          }
          ... on LocalReference {
            __typename
            sys {
              id
            }
            reference {
              node {
                ... on ${UseCaseResourceLink} {
                  sys {
                    id
                  }
                   slug
                   title
                   isPage
                    objectName
                    country
                    headerImage {
                      title
                      fileName
                      size
                      url
                      contentType
                    }
                }
              }
            }
          }
        }
      }
      globalGenericShelfElements(skip: $skip, limit: $limit) {
        items {
            node {
              ... on ${CareerInsightPageResourceLink} {
                sys {
                  id
                }
                slug
                title
                body {
                  json
                }
                country
                image {
                  title
                  fileName
                  size
                  url
                  contentType
                }
              }
            }
        } 
      }
    }    
  }
`;
