<template>
  <footer class="footer">
    <div class="footer-inner dk-section">
      <ul
        itemscope
        itemtype="http://schema.org/SiteNavigationElement"
        class="footer__nav"
      >
        <li
          class="footer__nav-item"
          v-for="legalNavigationTreeItem of legalNavigationTree"
          :key="legalNavigationTreeItem.id"
        >
          <DKLink
            class="footer__link"
            :href="legalNavigationTreeItem.path"
            @click.prevent="onClick(legalNavigationTreeItem)"
            itemprop="url"
          >
            <span itemprop="name">
              {{ legalNavigationTreeItem.label }}
            </span>
          </DKLink>
        </li>
      </ul>
      <div class="footer__media">
        <ul class="footer__media-list">
          <li v-if="hasFacebookUrl">
            <a
              :href="$t('footer.social_media.facebook.url')"
              target="_blank"
              rel="noopener nofollow"
              itemprop="sameAs"
            >
              <DKIcon icon="facebook" :size="14"></DKIcon>
            </a>
          </li>
          <li v-if="hasTwitterUrl">
            <a
              :href="$t('footer.social_media.twitter.url')"
              target="_blank"
              rel="noopener nofollow"
              itemprop="sameAs"
            >
              <DKIcon icon="x" :size="14"></DKIcon>
            </a>
          </li>
          <li v-if="hasYoutubeUrl">
            <a
              :href="$t('footer.social_media.youtube.url')"
              target="_blank"
              rel="noopener nofollow"
              itemprop="sameAs"
            >
              <DKIcon icon="youtube" :size="14"></DKIcon>
            </a>
          </li>
          <li v-if="hasLinkedinUrl">
            <a
              :href="$t('footer.social_media.linkedin.url')"
              target="_blank"
              rel="noopener nofollow"
              itemprop="sameAs"
            >
              <DKIcon icon="linkedin" :size="14"></DKIcon>
            </a>
          </li>
          <li v-if="hasInstagramUrl">
            <a
              :href="$t('footer.social_media.instagram.url')"
              target="_blank"
              rel="noopener nofollow"
              itemprop="sameAs"
            >
              <DKIcon icon="instagram" :size="14"></DKIcon>
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__copyright">
        <p>
          © dormakaba {{ $t(`location.group.label`) }}
          {{ new Date().getFullYear() }} all rights reserved
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { openLink, DKLink, DKIcon } from '@dormakaba/dormakaba-components';

@Component({
  components: {
    DKLink,
    DKIcon,
  },
})
export default class Footer extends Vue {
  @Prop() legalNavigationTree!: NavigationTree;

  onClick(navigationTreeItem: NavigationTreeItem) {
    openLink(navigationTreeItem.path, {
      target: navigationTreeItem.target,
    });
  }

  get hasFacebookUrl(): boolean {
    return !!this.$t('footer.social_media.facebook.url');
  }

  get hasTwitterUrl(): boolean {
    return !!this.$t('footer.social_media.twitter.url');
  }

  get hasYoutubeUrl(): boolean {
    return !!this.$t('footer.social_media.youtube.url');
  }

  get hasLinkedinUrl(): boolean {
    return !!this.$t('footer.social_media.linkedin.url');
  }

  get hasInstagramUrl(): boolean {
    return !!this.$t('footer.social_media.instagram.url');
  }
}
</script>

<style lang="postcss" scoped>
.footer {
  @apply bg-black text-white;

  & .footer-inner {
    @apply flex flex-col;
    @apply gap-y-4;
    @apply w-full;
    @apply py-4;
    @apply z-50;
    @apply max-w-container-default;
    @apply mx-auto;

    @apply lg:flex-row lg:justify-between;
    @apply lg:gap-x-4;
    @apply lg:py-6;
  }
}

.footer__nav {
  @apply flex flex-wrap;
  @apply gap-2;
  @apply w-full;

  @apply md:gap-x-10;
  @apply lg:gap-x-6;
}

.footer__media {
  @apply w-full;

  @apply lg:w-fit;
  @apply lg:flex lg:items-center;
}

.footer__media-list {
  @apply flex flex-wrap;
  @apply gap-4;

  @apply lg:flex-nowrap;
}

.footer__copyright {
  @apply flex items-center justify-start;
  @apply w-full;

  @apply lg:justify-end;

  & p {
    @apply text-grey-space;
  }
}

.footer__link,
.footer__copyright p {
  @apply p-small;
  @apply font-familyLight no-underline !important;
}

a {
  @apply text-white;

  &:hover,
  &.dk-link:hover > :first-child {
    @apply text-grey-frigate no-underline !important;
  }
}

ul {
  @apply p-0;
}
</style>
