import gql from 'graphql-tag';

export const linkQuery = (locale: string) => {
  return gql`
    query ($id: String!, $locale: String = "${locale}", $preview: Boolean = false) {
      link(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        link
        enLink: link(locale: "en-US")
        deLink: link(locale: "de-CH")
        label
      }
    }
  `;
};
