import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6db2dcaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-inner dk-section" }
const _hoisted_3 = {
  itemscope: "",
  itemtype: "http://schema.org/SiteNavigationElement",
  class: "footer__nav"
}
const _hoisted_4 = { itemprop: "name" }
const _hoisted_5 = { class: "footer__media" }
const _hoisted_6 = { class: "footer__media-list" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["href"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 2 }
const _hoisted_12 = ["href"]
const _hoisted_13 = { key: 3 }
const _hoisted_14 = ["href"]
const _hoisted_15 = { key: 4 }
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "footer__copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DKLink = _resolveComponent("DKLink")!
  const _component_DKIcon = _resolveComponent("DKIcon")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legalNavigationTree, (legalNavigationTreeItem) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "footer__nav-item",
            key: legalNavigationTreeItem.id
          }, [
            _createVNode(_component_DKLink, {
              class: "footer__link",
              href: legalNavigationTreeItem.path,
              onClick: _withModifiers(($event: any) => (_ctx.onClick(legalNavigationTreeItem)), ["prevent"]),
              itemprop: "url"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(legalNavigationTreeItem.label), 1)
              ]),
              _: 2
            }, 1032, ["href", "onClick"])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("ul", _hoisted_6, [
          (_ctx.hasFacebookUrl)
            ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                _createElementVNode("a", {
                  href: _ctx.$t('footer.social_media.facebook.url'),
                  target: "_blank",
                  rel: "noopener nofollow",
                  itemprop: "sameAs"
                }, [
                  _createVNode(_component_DKIcon, {
                    icon: "facebook",
                    size: 14
                  })
                ], 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasTwitterUrl)
            ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                _createElementVNode("a", {
                  href: _ctx.$t('footer.social_media.twitter.url'),
                  target: "_blank",
                  rel: "noopener nofollow",
                  itemprop: "sameAs"
                }, [
                  _createVNode(_component_DKIcon, {
                    icon: "x",
                    size: 14
                  })
                ], 8, _hoisted_10)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasYoutubeUrl)
            ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                _createElementVNode("a", {
                  href: _ctx.$t('footer.social_media.youtube.url'),
                  target: "_blank",
                  rel: "noopener nofollow",
                  itemprop: "sameAs"
                }, [
                  _createVNode(_component_DKIcon, {
                    icon: "youtube",
                    size: 14
                  })
                ], 8, _hoisted_12)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasLinkedinUrl)
            ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                _createElementVNode("a", {
                  href: _ctx.$t('footer.social_media.linkedin.url'),
                  target: "_blank",
                  rel: "noopener nofollow",
                  itemprop: "sameAs"
                }, [
                  _createVNode(_component_DKIcon, {
                    icon: "linkedin",
                    size: 14
                  })
                ], 8, _hoisted_14)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasInstagramUrl)
            ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
                _createElementVNode("a", {
                  href: _ctx.$t('footer.social_media.instagram.url'),
                  target: "_blank",
                  rel: "noopener nofollow",
                  itemprop: "sameAs"
                }, [
                  _createVNode(_component_DKIcon, {
                    icon: "instagram",
                    size: 14
                  })
                ], 8, _hoisted_16)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("p", null, " © dormakaba " + _toDisplayString(_ctx.$t(`location.group.label`)) + " " + _toDisplayString(new Date().getFullYear()) + " all rights reserved ", 1)
      ])
    ])
  ]))
}