import {
  Asset,
  GenericShelfFragment,
  LocalReference,
  Quote,
} from '../../../types/contentful-api';
import { DKUseCase, DKUseCaseVideoShelfFragment } from '@/models/DKUseCase';

import { AssetFactory } from '@/models/factories/AssetFactory';
import { DKAsset } from '@/models/DKAsset';
import { DKGenericShelfElement } from '../DKGenericShelfElement';
import { DKLink } from '@/models/DKLink';
import { DKQuote } from '@/models/DKQuote';
import { DKUseCaseVideo } from '@/models/DKUseCaseVideo';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { GenericShelfFragmentFactory } from './GenericShelfFragmentFactory';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { QuoteFactory } from '@/models/factories/QuoteFactory';
import { UseCase } from '../../../types/global-contentful-api';
import { UseCaseVideoFactory } from '@/models/factories/UseCaseVideoFactory';
import { getLanguageFromLocale } from '@/helpers/i18n';

export class UseCaseFactory extends GenericFactory<
  LocalReference | UseCase,
  DKUseCase
> {
  contentType = 'UseCase';
  requiredProperties = ['slug', 'title', 'headerImage'];

  protected map(source: LocalReference & UseCase): DKUseCase {
    let headerImage: DKAsset | undefined;
    let metaImage: DKAsset | undefined;
    let aboutImage: DKAsset | undefined;
    let challengesImage: DKAsset | undefined;
    let video: DKUseCaseVideo | undefined;
    let videoShelfFragment: DKUseCaseVideoShelfFragment | undefined;
    let callToActionLink: DKLink | undefined;
    let link: string | undefined;
    const pictureGallery: DKAsset[] = [];
    const quotes: DKQuote[] = [];

    if (source.headerImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        headerImage = assetFactory.create(source.headerImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HeaderImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.metaImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.quotesCollection?.items) {
      const quoteFactory = new QuoteFactory(this.locale);
      const items: Quote[] = source.quotesCollection.items as Quote[];
      items.forEach((item: Quote) => {
        try {
          quotes.push(quoteFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Quote');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.video) {
      const useCaseVideoFactory = new UseCaseVideoFactory(this.locale);

      const videoData = source.video;
      switch (videoData.__typename) {
        case 'Video':
        case 'ExternalVideo':
          try {
            video = useCaseVideoFactory.create(videoData);
          } catch (e: any) {
            if (e instanceof PropertyRequiredError) {
              console.warn('Could not generate Video');
              console.warn(e.message);
            } else {
              throw e;
            }
          }
          break;
        case 'GenericShelfFragment': {
          try {
            const genericShelfFactory = new GenericShelfFragmentFactory(
              this.locale
            );
            const shelfFragment = genericShelfFactory.create(
              videoData as GenericShelfFragment
            ) as DKUseCaseVideoShelfFragment;

            const videoElements =
              (
                shelfFragment?.genericShelfElements as DKGenericShelfElement[]
              )?.filter((it) => !!it?.video) || [];

            if (videoElements.length) {
              videoShelfFragment = {
                ...shelfFragment,
                genericShelfElements: videoElements,
              };
            }
          } catch (e: unknown) {
            if (e instanceof PropertyRequiredError) {
              console.warn('Could not generate Video Shelf fragment');
              console.warn(e.message);
            } else {
              throw e;
            }
          }
        }
      }
    }

    if (source.aboutImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        aboutImage = assetFactory.create(source.aboutImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate AboutImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.challengesImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        challengesImage = assetFactory.create(source.challengesImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate ChallengesImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.callToActionLink) {
      const linkFactory = new LinkFactory(this.locale);
      try {
        callToActionLink = linkFactory.create(source.callToActionLink);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.pictureGalleryCollection?.items) {
      const assetFactory = new AssetFactory(this.locale);
      const items: Asset[] = source.pictureGalleryCollection.items as Asset[];
      items.forEach((item: Asset) => {
        try {
          pictureGallery.push(assetFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Picture gallery');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.isPage && source.slug) {
      link = `/${getLanguageFromLocale(this.locale)}/use-case/${source.slug}`;
    }

    return Object.assign({} as DKUseCase, {
      ...source,
      __typename: 'UseCase',
      headerImage,
      metaImage,
      aboutImage,
      challengesImage,
      callToActionLink,
      quotes,
      video,
      videoShelfFragment,
      pictureGallery,
      link,
    });
  }
}
